
@media screen and (max-width: 2560px) and (min-width: 1441px) {

    .fq-container {
        background-color: #1A83c6;
        padding: 35px;
        /* Add padding for better readability */
        color: white;
        font-family:'Times New Roman', Times, serif;
    }

    .faq-item {
        cursor: pointer;
        border-bottom: rgba(183, 183, 183, 0.674) 1px solid;
        margin-bottom: 10px;
    }


    .fq-container h1 {
        margin-top: 0;
        letter-spacing: 2.5px;
        margin-left: 21%;
    }

    .fq-container p {
        margin-top: 0;
        font-size: larger;
        letter-spacing: 1.5px;
        margin-left: 21%;
        max-width: 60%;
    }

    .custom-faq-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 8px;
        font-family: sans-serif;

    }

    .custom-faq-title {
        display: block;
        font-size: 1rem;
        /* font-weight: bold; */
        margin-bottom: 10px;
    }

    /* Styles for the custom FAQ heading */
    .custom-faq-heading {
        font-size: 2rem;
        font-weight: 750 !important;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
    }

    /* Styles for the custom FAQ questions */
    .custom-faq-container p {
        margin-bottom: 10px;
        line-height: 1.8;
        letter-spacing: 0.5px;
    }

    /* Styles for the custom FAQ answers */
    .custom-faq-container p:nth-child(even) {
        margin-left: 20px;
    }


    .faq-question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .faq-toggle {
        font-size: 1.6em;
    }

    .question {
        font-weight: 550;
        font-size: 1.6em;
        letter-spacing: 0.5px;
        /* Increase question font size */
    }

    .answer {
        font-size: 1.4em;
        letter-spacing: 0.5px;
        /* Increase answer font size */
    }

    
}



@media screen and (max-width: 1440px) and (min-width: 1025px) {

    .fq-container {
        background-color: #1A83c6;
        padding: 20px;
        /* Add padding for better readability */
        color: white;
        font-family:'Times New Roman', Times, serif;
    }

    .faq-item {
        cursor: pointer;
        border-bottom: rgba(183, 183, 183, 0.674) 1px solid;
        margin-bottom: 10px;
    }


    .fq-container h1 {
        margin-top: 0;
        letter-spacing: 2px;
        margin-left: 12%;
    }

    .fq-container p {
        margin-top: 0;
        letter-spacing: 1.5px;
        margin-left: 12%;
        max-width: 60%;
    }

    .custom-faq-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 8px;
        font-family: sans-serif;

    }

    .custom-faq-title {
        display: block;
        font-size: 0.85rem;
        /* font-weight: bold; */
        margin-bottom: 10px;
    }

    /* Styles for the custom FAQ heading */
    .custom-faq-heading {
        font-size: 1.8rem;
        font-weight: 750 !important;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
    }

    /* Styles for the custom FAQ questions */
    .custom-faq-container p {
        margin-bottom: 10px;
        line-height: 1.5;
        letter-spacing: 0.5px;
    }

    /* Styles for the custom FAQ answers */
    .custom-faq-container p:nth-child(even) {
        margin-left: 20px;
    }


    .faq-question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .faq-toggle {
        font-size: 1.5em;
    }

    .question {
        font-weight: 550;
        font-size: 1.2em;
        letter-spacing: 0.5px;
        /* Increase question font size */
    }

    .answer {
        font-size: 1.1em;
        letter-spacing: 0.5px;
        /* Increase answer font size */
    }

    
}


@media screen and (max-width: 1024px) and (min-width: 769px) {
    .fq-container {
        background-color: #1A83c6;
        padding: 20px;
        /* Add padding for better readability */
        color: white;
        font-family:'Times New Roman', Times, serif;
    }

    .faq-item {
        cursor: pointer;
        border-bottom: rgba(183, 183, 183, 0.674) 1px solid;
        margin-bottom: 10px;
    }


    .fq-container h1 {
        margin-top: 0;
        letter-spacing: 2px;
        margin-left: 2%;
        font-size: 2.1rem;
    }

    .fq-container p {
        margin-top: 0;
        letter-spacing: 1.5px;
        margin-left: 2%;
        max-width: 70%;
        font-size: 1.1rem;
    }

    .custom-faq-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 8px;
        font-family: sans-serif;

    }

    .custom-faq-title {
        display: block;
        font-size: 0.85rem;
        /* font-weight: bold; */
        margin-bottom: 10px;
    }

    /* Styles for the custom FAQ heading */
    .custom-faq-heading {
        font-size: 1.8rem;
        font-weight: 750 !important;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
    }

    /* Styles for the custom FAQ questions */
    .custom-faq-container p {
        margin-bottom: 10px;
        line-height: 1.5;
        letter-spacing: 0.5px;
    }

    /* Styles for the custom FAQ answers */
    .custom-faq-container p:nth-child(even) {
        margin-left: 20px;
    }


    .faq-question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .faq-toggle {
        font-size: 1.5em;
    }

    .question {
        font-weight: 550;
        font-size: 1.2em;
        letter-spacing: 0.5px;
        /* Increase question font size */
    }

    .answer {
        font-size: 1.1em;
        letter-spacing: 0.5px;
        /* Increase answer font size */
    }

}



@media screen and (max-width: 768px) and (min-width: 425px) {
    .fq-container {
        background-color: #1A83c6;
        padding: 20px;
        /* Add padding for better readability */
        color: white;
        font-family:'Times New Roman', Times, serif;
    }

    .faq-item {
        cursor: pointer;
        border-bottom: rgba(183, 183, 183, 0.674) 1px solid;
        margin-bottom: 10px;
    }


    .fq-container h1 {
        margin-top: 0;
        letter-spacing: 2px;
        margin-left: 2%;
        font-size: 2rem;
    }

    .fq-container p {
        margin-top: 0;
        letter-spacing: 1.5px;
        margin-left: 2%;
        max-width: 85%;
        font-size: 1rem;
    }

    .custom-faq-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 8px;
        font-family: sans-serif;

    }

    .custom-faq-title {
        display: block;
        font-size: 0.7rem;
        /* font-weight: bold; */
        margin-bottom: 10px;
    }

    /* Styles for the custom FAQ heading */
    .custom-faq-heading {
        font-size: 1.6rem;
        font-weight: 750 !important;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
    }

    /* Styles for the custom FAQ questions */
    .custom-faq-container p {
        margin-bottom: 10px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        font-size: 1.1rem;
    }

    /* Styles for the custom FAQ answers */
    .custom-faq-container p:nth-child(even) {
        margin-left: 20px;
    }


    .faq-question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .faq-toggle {
        font-size: 1.5rem;
    }

    .question {
        font-weight: 550;
        font-size: 1.1rem;
        letter-spacing: 0.5px;
        /* Increase question font size */
    }

    .answer {
        font-size: 1rem;
        letter-spacing: 0.5px;
        /* Increase answer font size */
    }

}



@media screen and (max-width: 424px) and (min-width: 375px) {
    .fq-container {
        background-color: #1A83c6;
        padding: 20px;
        /* Add padding for better readability */
        color: white;
        font-family:'Times New Roman', Times, serif;
    }

    .faq-item {
        cursor: pointer;
        border-bottom: rgba(183, 183, 183, 0.674) 1px solid;
        margin-bottom: 10px;
    }


    .fq-container h1 {
        margin-top: 0;
        letter-spacing: 2px;
        /* margin-left: %; */
        font-size: 1.8rem;
    }

    .fq-container p {
        margin-top: 0;
        letter-spacing: 1.5px;
        /* margin-left: 2%; */
        max-width: 95%;
        font-size: 0.8remrem;
    }

    .custom-faq-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 8px;
        font-family: sans-serif;

    }

    .custom-faq-title {
        display: block;
        font-size: 0.65rem;
        /* font-weight: bold; */
        margin-bottom: 10px;
    }

    /* Styles for the custom FAQ heading */
    .custom-faq-heading {
        font-size: 1.4rem;
        font-weight: 750 !important;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
    }

    /* Styles for the custom FAQ questions */
    .custom-faq-container p {
        margin-bottom: 10px;
        line-height: 1.3;
        letter-spacing: 0.5px;
        font-size: 1.1rem;
    }

    /* Styles for the custom FAQ answers */
    .custom-faq-container p:nth-child(even) {
        margin-left: 20px;
    }


    .faq-question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    /* .faq-toggle {
        font-size: 1.5em;
    } */

    .question {
        font-weight: 550;
        font-size: 1rem;
        letter-spacing: 0.5px;
        /* Increase question font size */
    }

    .answer {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        /* Increase answer font size */
    }
}

@media screen and (max-width: 374px) and (min-width: 321px) {
    .fq-container {
        background-color: #1A83c6;
        padding: 20px;
        /* Add padding for better readability */
        color: white;
        font-family:'Times New Roman', Times, serif;
    }

    .faq-item {
        cursor: pointer;
        border-bottom: rgba(183, 183, 183, 0.674) 1px solid;
        margin-bottom: 10px;
    }


    .fq-container h1 {
        margin-top: 0;
        letter-spacing: 2px;
        /* margin-left: %; */
        font-size: 1.6rem;
    }

    .fq-container p {
        margin-top: 0;
        letter-spacing: 1.5px;
        /* margin-left: 2%; */
        max-width: 95%;
        font-size: 0.8remrem;
    }

    .custom-faq-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 8px;
        font-family: sans-serif;

    }

    .custom-faq-title {
        display: block;
        font-size: 0.65rem;
        /* font-weight: bold; */
        margin-bottom: 10px;
    }

    /* Styles for the custom FAQ heading */
    .custom-faq-heading {
        font-size: 1.25rem;
        font-weight: 750 !important;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
    }

    /* Styles for the custom FAQ questions */
    .custom-faq-container p {
        margin-bottom: 10px;
        line-height: 1.3;
        letter-spacing: 0.5px;
        font-size: 1.1rem;
    }

    /* Styles for the custom FAQ answers */
    .custom-faq-container p:nth-child(even) {
        margin-left: 20px;
    }


    .faq-question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    /* .faq-toggle {
        font-size: 1.5em;
    } */

    .question {
        font-weight: 550;
        font-size: 1rem;
        letter-spacing: 0.5px;
        /* Increase question font size */
    }

    .answer {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        /* Increase answer font size */
    }
}

@media screen and (max-width: 320px) and (min-width: 220px) {
    .fq-container {
        background-color: #1A83c6;
        padding: 20px;
        /* Add padding for better readability */
        color: white;
        font-family:'Times New Roman', Times, serif;
    }

    .faq-item {
        cursor: pointer;
        border-bottom: rgba(183, 183, 183, 0.674) 1px solid;
        margin-bottom: 10px;
    }


    .fq-container h1 {
        margin-top: 0;
        letter-spacing: 2px;
        /* margin-left: %; */
        font-size: 1.6rem;
    }

    .fq-container p {
        margin-top: 0;
        letter-spacing: 1.5px;
        /* margin-left: 2%; */
        max-width: 95%;
        font-size: 0.8remrem;
    }

    .custom-faq-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 8px;
        font-family: sans-serif;

    }

    .custom-faq-title {
        display: block;
        font-size: 0.65rem;
        /* font-weight: bold; */
        margin-bottom: 10px;
    }

    /* Styles for the custom FAQ heading */
    .custom-faq-heading {
        font-size: 1.25rem;
        font-weight: 750 !important;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
    }

    /* Styles for the custom FAQ questions */
    .custom-faq-container p {
        margin-bottom: 10px;
        line-height: 1.3;
        letter-spacing: 0.5px;
        font-size: 1.1rem;
    }

    /* Styles for the custom FAQ answers */
    .custom-faq-container p:nth-child(even) {
        margin-left: 20px;
    }


    .faq-question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    /* .faq-toggle {
        font-size: 1.5em;
    } */

    .question {
        font-weight: 550;
        font-size: 1rem;
        letter-spacing: 0.5px;
        /* Increase question font size */
    }

    .answer {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        /* Increase answer font size */
    }
}