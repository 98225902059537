@media screen and (max-width: 2560px) and (min-width: 1441px) {


    .footer-heading {
        color: #ffffff;
        font-size: 1.5rem;
        letter-spacing: 1px;
        font-weight: 600;
        font-family: sans-serif;
        position: relative;
    }

    .footer-heading::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 2.5px;
        background-color: orange;
    }

    .footer-link {
        text-decoration: none;
        color: inherit;
        word-spacing: 0.2rem;
    }

    span.footer-link {
        font-size: 0.6rem;

    }

    .footer-contact-info {
        word-spacing: 0.2rem;
    }

    .list-unstyled li {
        font-size: 1.4rem;
        margin-bottom: 2px;
    }

    ul.list-unstyled {
        margin-top: 10px;
    }

    .image-custom {
        width: 200px;
        height: 100px;
        margin-left: 5%;
    }

    .social-icons-container {
        display: flex;
        gap: 15px;
        margin-top: 10px;
    }

    .custom-social-icon {
        width: 3rem !important;
        height: 3rem !important;
        border: 2.5px solid white;
        border-radius: 30px;
    }

    .rights p {
        font-size: 20px;
    }

    .list-unstyled img,
    .list-unstyled svg {
        height: 30px;
        width: 30px;
    }

}



@media screen and (max-width: 1440px) and (min-width: 1025px) {


    .footer-heading {
        color: #ffffff;
        font-size: 1.2rem;
        letter-spacing: 1px;
        font-weight: 600;
        font-family: sans-serif;
        position: relative;
    }

    .footer-heading::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 2.5px;
        background-color: orange;
    }

    .footer-link {
        text-decoration: none;
        color: inherit;
        word-spacing: 0.2rem;
    }

    span.footer-link {
        font-size: 0.6rem;

    }

    .footer-contact-info {
        word-spacing: 0.2rem;
    }

    .list-unstyled li {
        font-size: 1rem;
        margin-bottom: 2px;
    }

    ul.list-unstyled {
        margin-top: 10px;
    }

    .image-custom {
        width: 200px;
        height: 100px;
        margin-left: 5%;
    }

    .social-icons-container {
        display: flex;
        gap: 15px;
        margin-top: 10px;
    }

    .custom-social-icon {
        width: 2.5rem !important;
        height: 2.5rem !important;
        border: 2px solid white;
        border-radius: 30px;
    }

    .rights p {
        font-size: 14px;
    }

    .list-unstyled img,
    .list-unstyled svg {
        height: 24px;
        width: 24px;
    }

}




@media screen and (max-width: 1024px) and (min-width: 769px) {
    .footer-heading {
        color: #ffffff;
        font-size: 1.2rem;
        letter-spacing: 1px;
        font-weight: 600;
        font-family: sans-serif;
        position: relative;
    }

    .footer-heading::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 2.5px;
        background-color: orange;
    }

    .footer-link {
        text-decoration: none;
        color: inherit;
        word-spacing: 0.2rem;
    }

    span.footer-link {
        font-size: 0.6rem;

    }

    .footer-contact-info {
        word-spacing: 0.2rem;
    }

    .list-unstyled li {
        font-size: 1rem;
        margin-bottom: 2px;
    }

    ul.list-unstyled {
        margin-top: 10px;
    }

    .image-custom {
        width: 200px;
        height: 100px;
        /* margin-left: 5%; */
    }

    .social-icons-container {
        display: flex;
        gap: 15px;
        margin-top: 10px;
    }

    .custom-social-icon {
        width: 2.5rem !important;
        height: 2.5rem !important;
        border: 1.8px solid white;
        border-radius: 30px;
    }

    .rights p {
        font-size: 15px;
    }

    .list-unstyled img,
    .list-unstyled svg {
        height: 22px;
        width: 22px;
    }
}



@media screen and (max-width: 768px) and (min-width: 426px) {
    .footer-heading {
        color: #ffffff;
        font-size: 1.2rem;
        letter-spacing: 1px;
        font-weight: 600;
        font-family: sans-serif;
        position: relative;
    }

    .footer-heading::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 2.5px;
        background-color: orange;
    }

    .footer-link {
        text-decoration: none;
        color: inherit;
        word-spacing: 0.2rem;
    }

    span.footer-link {
        font-size: 0.6rem;

    }

    .footer-contact-info {
        word-spacing: 0.2rem;
    }

    .list-unstyled li {
        font-size: 1rem;
        margin-bottom: 2px;
        display: block;
    }

    ul.list-unstyled {
        margin-top: 10px;
    }

    .image-custom {
        width: 200px;
        height: 110px;
        margin-left: 5%;
    }

    .social-icons-container {
        display: flex;
        gap: 15px;
        margin-top: 10px;
    }

    .custom-social-icon {
        width: 2.5rem !important;
        height: 2.5rem !important;
        border: 1.7px solid white;
        border-radius: 30px;
    }

    .rights p {
        font-size: 12px !important;
    }

    .list-unstyled img,
    .list-unstyled svg {
        height: 20px;
        width: 20px;
    }
      
}


@media screen and (max-width: 425px) and (min-width: 375px) {
    .footer-heading {
        color: #ffffff;
        font-size: 1rem;
        letter-spacing: 1px;
        font-weight: 600;
        font-family: sans-serif;
        position: relative;
    }

    .footer-heading::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 1.8px;
        background-color: orange;
    }

    .footer-link {
        text-decoration: none;
        color: inherit;
        word-spacing: 0.2rem;
    }

    span.footer-link {
        font-size: 0.6rem;

    }

    .footer-contact-info {
        word-spacing: 0.2rem;
    }

    .list-unstyled li {
        font-size: 0.8rem;
        margin-bottom: 2px;
        display: block;
    }

    ul.list-unstyled {
        margin-top: 10px;
    }

    .image-custom {
        width: 160px;
        height: 80px;
        margin-left: 5%;
    }

    .social-icons-container {
        display: flex;
        gap: 15px;
        margin-top: 10px;
    }

    .custom-social-icon {
        
        width: 2rem !important;
        height: 2rem !important;
        border: 1.6px solid white;
        border-radius: 30px;
    }

    .rights p {
        font-size: 10px !important;
        margin-top: 14px !important;
    }

    .list-unstyled img,
    .list-unstyled svg {
        height: 17px;
        width: 17px;
    }

    .my-10{
        margin-bottom: 10px !important;
    }
      
}

@media screen and (max-width: 374px) and (min-width: 321px) {
    .footer-heading {
        color: #ffffff;
        font-size: 0.95rem;
        letter-spacing: 1px;
        font-weight: 600;
        font-family: sans-serif;
        position: relative;
    }

    .footer-heading::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 1.8px;
        background-color: orange;
    }

    .footer-link {
        text-decoration: none;
        color: inherit;
        word-spacing: 0.2rem;
    }

    span.footer-link {
        font-size: 0.6rem;

    }

    .footer-contact-info {
        word-spacing: 0.2rem;
    }

    .list-unstyled li {
        font-size: 0.75rem;
        margin-bottom: 2px;
        display: block;
    }

    ul.list-unstyled {
        margin-top: 10px;
    }

    .image-custom {
        width: 150px;
        height: 75px;
        margin-left: 5%;
    }

    .social-icons-container {
        display: flex;
        gap: 15px;
        margin-top: 10px;
    }

    .custom-social-icon {
        
        width: 1.8rem !important;
        height: 1.8rem !important;
        border: 1.6px solid white;
        border-radius: 30px;
    }

    .rights p {
        font-size: 10px !important;
        margin-top: 14px !important;
    }

    .list-unstyled img,
    .list-unstyled svg {
        height: 17px;
        width: 17px;
    }

    .my-10{
        margin-bottom: 10px !important;
    }

    .py-4 {
        padding-top: 0.3rem !important;
    }
      
}

@media screen and (max-width: 320px) and (min-width: 220px) {
    .footer-heading {
        color: #ffffff;
        font-size: 0.95rem;
        letter-spacing: 1px;
        font-weight: 600;
        font-family: sans-serif;
        position: relative;
    }

    .footer-heading::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 1.8px;
        background-color: orange;
    }

    .footer-link {
        text-decoration: none;
        color: inherit;
        word-spacing: 0.2rem;
    }

    span.footer-link {
        font-size: 0.6rem;

    }

    .footer-contact-info {
        word-spacing: 0.2rem;
    }

    .list-unstyled li {
        font-size: 0.75rem;
        margin-bottom: 2px;
        display: block;
    }

    ul.list-unstyled {
        margin-top: 10px;
    }

    .image-custom {
        width: 150px;
        height: 75px;
        margin-left: 5%;
    }

    .social-icons-container {
        display: flex;
        gap: 15px;
        margin-top: 10px;
    }

    .custom-social-icon {
        
        width: 1.8rem !important;
        height: 1.8rem !important;
        border: 1.6px solid white;
        border-radius: 30px;
    }

    .rights p {
        font-size: 10px !important;
        margin-top: 14px !important;
    }

    .list-unstyled img,
    .list-unstyled svg {
        height: 17px;
        width: 17px;
    }

    .my-10{
        margin-bottom: 10px !important;
    }

    .py-4 {
        padding-top: 0.3rem !important;
    }
      
}