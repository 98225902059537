/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */



/* Heder commpnet*/
/* .nav-link {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: sans-serif;
}

.navbar-nav .nav-link {
  margin-right: 15px;

}

.navbar-nav .dropdown-item {
  margin-bottom: 5px;
  font-size: 15px !important;
  font-family: sans-serif;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  width: 150px;

  height: auto;
  margin-right: 10px;
 
} */


/* footer.css */

/* .footer-heading {
  color: rgb(255, 255, 255); 
  font-size: 1.5rem;
  font-weight: 600; 
  font-family: sans-serif;
  position: relative;
}

.footer-heading::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -6px; 
  width: 100%; 
  height: 4px; 
  background-color: orange;
  
}

.footer-link {
  text-decoration: none;
  color: inherit;
  
  word-spacing: 0.2rem; 
}

.footer-contact-info {
  word-spacing: 0.2rem;
}

.list-unstyled li {
  font-size: 1.3rem;
  margin-bottom: 2px;
}

ul.list-unstyled {
  margin-top: 10px; 
} */



/*Header Under line*/
/* .nav-link .underline-word {
  position: relative;
  font-size: 1.3rem;
  font-weight: 600 !important;
} */











/* Footer Style under line*/
.custom-col .underline-word {
  position: relative;
  /* font-size: 1.3rem; */
}

.custom-col .underline-word::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  /* Adjust the position to match your design */
  width: 0;
  height: 2px;
  /* Adjust the height as needed */
  background-color: orange;
  transition: width 0.3s ease;
  /* Add transition effect for the width */
}

.custom-col .underline-word:hover::after {
  width: 100%;
}

.social-svg-icon {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 30px;
}

/* .custom-class:hover .social-svg-icon {
  fill: rgb(0, 0, 0) !important;
  border: 2px solid black !important;
} */


/* .world {
  background-image: url("/src/image/world2.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Adjust the opacity of the background image */
/* .world::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust the opacity here */
/* }  */

/* Optional: Adjust the opacity of the content */



/*Quality page*/
/* 
.quality-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;

}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/src/image/panther exim.png");
  background-size: cover;
  background-position: center;
  opacity: 0.3;
}


.title {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Amiri', serif;
}

.title h2 {
  font-weight: 700;
  font-size: 2rem;
}

.content p {
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 1.4rem;
  font-family: sans-serif;

  color: #616161;
}

.content ul {
  list-style-type: none;
  color: #616161;
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
}

.content ul li {
  margin-bottom: 8px;
  list-style-type: disc;
  font-size: 1.4rem;
  font-weight: 250;
} */



/*Contact page*/

/* .company-info {
  background-color: #4a5568; 
  margin:0 30px;
  padding: 2rem;
  border-radius: 0.5rem;
}

.company-info img {
  width: 100px; 
  height: auto;
  margin-bottom: 1rem; 
} */




/*About page*/





@media screen and (max-width: 1440px) and (min-width: 1026px) {

  /*About pag*/




  /* Heder commpnet*/
  .nav-link {
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: sans-serif;
  }

  .navbar-nav .nav-link {
    margin-right: 15px;

  }

  .navbar-nav .dropdown-item {
    margin-bottom: 5px;
    font-size: 15px !important;
    font-family: sans-serif;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
  }

  .navbar-brand img {
    width: 130px;
    /* Adjust the width as needed */
    height: auto;
    /* Maintain aspect ratio */
    margin-right: 10px;
    /* Add some margin between the image and the text (optional) */
  }

  .nav-link .underline-word {
    position: relative;
    font-size: 1.1rem;
    font-weight: 600 !important;
  }





}




@media screen and (max-width: 1025px) and (min-width: 769px) {

  /*About pag*/







  /*contact us*/


  /*Footer*/

}

/* For viewport heights between 779px and 450px */
@media screen and (max-width: 769px) and (min-width: 427px) {

  /*About pag*/






  /*contact us*/



  /*Footer*/

}

@media screen and (max-width: 426px) and (min-width: 377px) {










  /*contact us*/



  /* Footer */
  .rights p {
    font-size: 15px;
  }

  .footer-heading {
    color: rgb(255, 255, 255);
    /* Text color */
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: sans-serif;
    position: relative;
  }

  .footer-heading::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    /* Adjust the position to match your design */
    width: 100%;
    /* Full width */
    height: 2px;
    /* Adjust the height as needed */
    background-color: orange;
    /* margin-top: 8px;  */
  }

  .footer-link {
    text-decoration: none;
    color: inherit;
    /* margin-bottom: 0.25rem; Add margin bottom for spacing */
    word-spacing: 0.2rem;
    /* Add spacing between words */
  }

  span.footer-link {
    font-size: 1rem;

  }

  .footer-contact-info {
    word-spacing: 0.2rem;
    /* Add spacing between words */
  }

  .list-unstyled li {
    font-size: 1.3rem;
    margin-bottom: 2px;
  }

  ul.list-unstyled {
    margin-top: 10px;
    /* Add margin between the span and the ul */
  }

  .image-custom {
    width: 200px;
    height: 100px;
    margin-left: 10%;
  }

  .social-icons-container {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }

  .custom-social-icon {
    width: 30px;
    height: 30px;
    border: 1.5px solid white;
    border-radius: 30px;
  }

}

@media screen and (max-width: 376px) and (min-width: 321px) {
  /*About pag*/







  /*contact us*/


  /*Footer */


}

@media screen and (max-width: 320px) and (min-width: 278px) {

  /*About pag*/






  /*contact us*/
  .company-info .logo {
    width: 200px;
    height: auto;
    margin-left: 30px;
    margin-bottom: 0.5rem;
  }

  .company-info {
    /* margin:0 30px; */
    /* padding: 1rem;  */
    border-radius: 0.5rem;
  }

  /*Footer*/
  .rights p {
    font-size: 10px;
  }

  .footer-heading {
    color: rgb(255, 255, 255);
    /* Text color */
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: sans-serif;
    position: relative;
  }

  .footer-heading::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    /* Adjust the position to match your design */
    width: 100%;
    /* Full width */
    height: 2px;
    /* Adjust the height as needed */
    background-color: orange;
    /* margin-top: 8px;  */
  }

  .footer-link {
    text-decoration: none;
    color: inherit;
    /* margin-bottom: 0.25rem; Add margin bottom for spacing */
    word-spacing: 0.2rem;
    /* Add spacing between words */
  }

  span.footer-link {
    font-size: 1rem;

  }

  .footer-contact-info {
    word-spacing: 0.2rem;
    /* Add spacing between words */
  }

  .list-unstyled li {
    font-size: 1rem;
    margin-bottom: 2px;
  }

  ul.list-unstyled {
    margin-top: 10px;
    /* Add margin between the span and the ul */
  }

  .image-custom {
    width: 200px;
    height: 100px;
    margin-left: 10%;
  }

  .social-icons-container {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }

  .custom-social-icon {
    width: 30px;
    height: 30px;
    border: 1.5px solid white;
    border-radius: 30px;
  }
}

.about h1 {

  text-align: center;
  /* justify-content: center; */

  letter-spacing: 2px;

  opacity: 1;
}




/*About */





/*Home*/

/* Style for the main div */
.company-at-glance {
  margin: 20px;
}

/* Style for the heading */
.company-at-glance h2 {
  color: #333;
  text-align: center;
}

/* Style for the container */
.company-container {
  margin-top: 20px;
}

/* Style for each row */
.company-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Style for each column */
.company-col {
  flex: 0 0 calc(33.33% - 20px);
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Style for the SVG icons */
.company-col .icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
}

/* Center SVG icons vertically */
.company-col svg {
  display: inline-block;
  vertical-align: middle;
}

/* Style for the headings inside columns */
.company-col h4 {
  margin: 0 0 10px;
}

/* Style for the spans inside columns */
.company-col span {
  display: block;
  margin-bottom: 5px;
}

/* Style for the "READ MORE" spans */
.company-col .read-more {
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}

/* Hover effect for "READ MORE" spans */
.company-col .read-more:hover {
  text-decoration: underline;
}

/* Add space between call icon and text */
.company-col .call-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


/*FQ*/






/* Category*/

.category {
  display: grid;
  font-family: amiri, serif;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* Adjust the gap between divs */
  margin: 20px;
  /* Margin on all sides */
}

.category>div {
  border: 1px solid #ccc;
  /* Border style */
  border-radius: 10px;
  /* Border radius */
  padding: 10px;
  /* Padding for content */
  transition: all 0.3s ease;
  /* Smooth transition effect */
}

.category>div:hover {
  transform: scale(1.05);
  /* Increase size on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add box shadow on hover */
  border-color: #007bff;
  /* Change border color on hover */
}

.category>div>p {
  text-align: center;
  /* Center align the text */
}

.category>div>img {
  max-width: 100%;
  /* Ensure images don't overflow */
}


/*product header*/



/* Sub Category*/

.subCategory {
  display: grid;
  font-family: amiri, serif;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* Adjust the gap between divs */
  margin: 20px;
  /* Margin on all sides */
}

.subCategory>div {
  border: 1px solid #ccc;
  /* Border style */
  border-radius: 10px;
  /* Border radius */
  padding: 10px;
  /* Padding for content */
  transition: all 0.3s ease;
  /* Smooth transition effect */
}

.subCategory>div:hover {
  transform: scale(1.05);
  /* Increase size on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add box shadow on hover */
  border-color: #007bff;
  /* Change border color on hover */
}

.subCategory>div>p {
  text-align: center;
  /* Center align the text */
}

.subCategory>div>img {
  max-width: 100%;
  /* Ensure images don't overflow */
}

.subCategoryh1 {
  text-align: center;
  font-size: 21px;
  font-family: amiri, serif;
  /* margin: 10px 0px; */
  font-weight: 600;
  /* background-color: #d6d6d6ab; */
  letter-spacing: 1px;
  padding: 5px;
  /* margin-top: 15PX; */
}

.subCategoryP {
  margin-top: 10px;
  text-align: center;
  margin-bottom: 0px !important;
  font-family: amiri, serif;
}






/*sub sub category*/
.supersubCategory {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.supersubCategory h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 3%;
  letter-spacing: 1px;
  font-family: Amiri, serif;
  text-align: center;
}

.subCatProduct {
  height: 500px;
  width: auto;
  margin-bottom: 20px;
}

.subCatTable {
  height: 500px;
  width: auto;
}

.productImageContainer,
.tableImageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Optional: Adjust margin as needed */
}

.supersubCategory p {

  font-size: 1.2rem;
  font-weight: 500;
  color: #666;
  /* Medium dark text color */
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 17%;
  /* Add left margin */
  margin-right: 0;
  /* Ensure no margin on the right side */
  align-self: flex-start;
  /* Override align-items for <p> tags */
}


/*Home*/


.home-content {
  display: grid;
  font-family: 'Times New Roman', Times, serif;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  text-align: center;
  margin: 20px;
}

.home-content h2 {
  grid-column: 1 / -1;
}

.home-content div {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.home-content img {
  width: 100%;
  height: auto;
  transition: border-color 0.3s ease;
}

.home-content div:hover {
  border-color: #007bff;
}

.item {
  position: relative;
  /* Ensure the text is positioned relative to the item */
}

.item img {
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease;
  /* Add transition for smoother effect */
}

.item:hover img {
  opacity: 0.7;
  /* Decrease opacity of the image on hover */
}

.item .text4 {
  position: absolute;
  top: 50%;
  /* Position text vertically at the center */
  left: 50%;
  /* Position text horizontally at the center */
  transform: translate(-50%, -50%);
  /* Center text */
  opacity: 0;
  /* Initially hide the text */
  transition: opacity 0.3s ease;
  /* Add transition effect */
  border: none;
  /* Remove border */
  color: #000000;
}

.item:hover .text4 {
  opacity: 1;
  /* Show the text on hover */
}

.item .line.middle {
  background-color: #333;
  /* Dark color for the line */
}

.vl span {
  font-family: "Nunito Sans", Sans-serif;
  ;
  color: white;
  /* background: #000; */
}

.vl h1 {
  font-family: Amiri, serif;
  color: white;
}

.Counts {
  background-color: #008DD2;
  margin: 10px 0px;
  padding: 15px 0px;
}






/* styles.css */