

@media screen and (max-width: 2560px) and (min-width: 1441px) {

    .product-header {
        color: #ffffff;
      }
      
      .product-header img {
        height: 20px;
        width: 20px;
      }
      
      .product-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
      }
      
      .header-title h3{
        font-size: 2.5rem;
      }
      
      .header-content span{
        font-size: 1.3rem;
      }
      
      .header-content span,
      .header-content img {
          display: inline-block; /* Display elements in the same line */
          vertical-align: middle; /* Align elements vertically */
          margin-right: 10px; /* Adjust margin as needed */
      }
      
      .header-content img{
        margin-top: 10px;
      }
      
      .header-content span:last-child {
          margin-right: 0; /* Remove margin from the last span */
      }
      
      .header-content span:hover a {
          color: orange;
      }
}





@media screen and (max-width: 1440px) and (min-width: 1025px) {
    .product-header {
        color: #ffffff;
      }
      
      .product-header img {
        height: 20px;
        width: 20px;
      }
      
      .product-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
      }
      
      .header-title h3{
        font-size: 1.5rem;
      }
      
      .header-content span{
        font-size: 1.3rem;
      }
      
      .header-content span,
      .header-content img {
          display: inline-block; /* Display elements in the same line */
          vertical-align: middle; /* Align elements vertically */
          margin-right: 10px; /* Adjust margin as needed */
      }
      
      .header-content img{
        margin-top: 10px;
      }
      
      .header-content span:last-child {
          margin-right: 0; /* Remove margin from the last span */
      }
      
      .header-content span:hover a {
          color: orange;
      }
}


@media screen and (max-width: 1024px) and (min-width: 769px) {

    .product-header {
        color: #ffffff;
      }
      
      .product-header img {
        height: 20px;
        width: 20px;
      }
      
      .product-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
      }
      
      .header-title h3{
        font-size: 1.5rem;
      }
      
      .header-content span{
        font-size: 1.3rem;
      }
      
      .header-content span,
      .header-content img {
          display: inline-block; /* Display elements in the same line */
          vertical-align: middle; /* Align elements vertically */
          margin-right: 10px; /* Adjust margin as needed */
      }
      
      .header-content img{
        margin-top: 10px;
      }
      
      .header-content span:last-child {
          margin-right: 0; /* Remove margin from the last span */
      }
      
      .header-content span:hover a {
          color: orange;
      }
}



@media screen and (max-width: 768px) and (min-width: 425px) {

    .product-header {
        color: #ffffff;
      }
      
      .product-header img {
        height: 20px;
        width: 20px;
      }
      
      .product-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
      }
      
      .header-title h3{
        font-size: 1.5rem;
      }
      
      .header-content span{
        font-size: 1.3rem;
      }
      
      .header-content span,
      .header-content img {
          display: inline-block; /* Display elements in the same line */
          vertical-align: middle; /* Align elements vertically */
          margin-right: 10px; /* Adjust margin as needed */
      }
      
      .header-content img{
        margin-top: 10px;
      }
      
      .header-content span:last-child {
          margin-right: 0; /* Remove margin from the last span */
      }
      
      .header-content span:hover a {
          color: orange;
      }
}



@media screen and (max-width: 424px) and (min-width: 375px) {

    .product-header {
        color: #ffffff;
      }
      
      .product-header img {
        height: 20px;
        width: 20px;
      }
      
      .product-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
      }
      
      .header-title h3{
        font-size: 1.5rem !important;
      }
      
      .header-content span{
        font-size: 1.3rem;
      }
      
      .header-content span,
      .header-content img {
          display: inline-block; /* Display elements in the same line */
          vertical-align: middle; /* Align elements vertically */
          margin-right: 10px; /* Adjust margin as needed */
      }
      
      .header-content img{
        margin-top: 10px;
      }
      
      .header-content span:last-child {
          margin-right: 0; /* Remove margin from the last span */
      }
      
      .header-content span:hover a {
          color: orange;
      }
}

@media screen and (max-width: 374px) and (min-width: 321px) {

    .product-header {
        color: #ffffff;
      }
      
      .product-header img {
        height: 20px;
        width: 20px;
      }
      
      .product-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
      }
      
      .header-title h3{
        font-size: 1.5rem !important;
      }
      
      .header-content span{
        font-size: 1.3rem;
      }
      
      .header-content span,
      .header-content img {
          display: inline-block; /* Display elements in the same line */
          vertical-align: middle; /* Align elements vertically */
          margin-right: 10px; /* Adjust margin as needed */
      }
      
      .header-content img{
        margin-top: 10px;
      }
      
      .header-content span:last-child {
          margin-right: 0; /* Remove margin from the last span */
      }
      
      .header-content span:hover a {
          color: orange;
      }
}

@media screen and (max-width: 320px) and (min-width: 220px) {

    .product-header {
        color: #ffffff;
      }
      
      .product-header img {
        height: 20px;
        width: 20px;
      }
      
      .product-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
      }
      
      .header-title h3{
        font-size: 2.5rem;
      }
      
      .header-content span{
        font-size: 1.3rem;
      }
      
      .header-content span,
      .header-content img {
          display: inline-block; /* Display elements in the same line */
          vertical-align: middle; /* Align elements vertically */
          margin-right: 10px; /* Adjust margin as needed */
      }
      
      .header-content img{
        margin-top: 10px;
      }
      
      .header-content span:last-child {
          margin-right: 0; /* Remove margin from the last span */
      }
      
      .header-content span:hover a {
          color: orange;
      }
}