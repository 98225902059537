/* 
  h3 {
    letter-spacing: 1px;
    line-height: 25px;
    margin: 0;
    color: #ffffff;
  }

  p {
    line-height: 25px;
    margin: 0;
    color: #ffffff;
  }
  #content {
    margin-top: 50px;
    margin-bottom: 30px;
    width: 100%;
    overflow:hidden;
  }
  .item {
    width: calc(94%/3);
    margin: 1%;
    float: left;
    overflow: hidden;
    position: relative;
  }
  .item img {
    max-width: 100%;
    display: block;
    transition: all 0.5s ease;
  }




.text4 {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 37%;
    opacity:0;
    transition: all 0.5s ease;
    cursor: pointer;
  }
  .text4 p{
    margin-top:20px;
  }
  
  .middle{
    position:absolute;
    top:50%;
    left:50%;
  }
  
  #e4bk{
    position:absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    background:#9a9999;
    opacity:0;
    transition: all 0.5s ease;
  }
  #e4bk:after{
     background-color: #000000;
      content: '';
      display: block;
      position: absolute;
      top: 5%;
      left: 5%;
      right: 5%;
      bottom: 5%;
    opacity:0;
    transition: all 0.5s ease;
  }
  
  .e4:hover .text4{
    opacity:1;
  }
  
  .e4:hover .middle{
    position: absolute;
    top: 50%;
    left:32%;
    width:36%;
    height:1px;
  }
  
  .e4:hover #e4bk{
    opacity:0.2;
  }
  .e4:hover #e4bk:after{
    opacity:1;
  }

 */

 .home-content {
    
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    text-align: center;
    
  }
  
.grid-container {
  margin-top: 3%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust column width as needed */
    gap: 20px; /* Adjust spacing between items as needed */
}

.grid-container h3 {
  font-family :'Times New Roman', Times, serif;
}

.grid-container > div {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust spacing between image and text as needed */
}

.grid-container img {
    width: 80px; /* Set image width */
    height: auto; /* Maintain aspect ratio */
}

  